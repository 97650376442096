import React from "react"
// import { graphql } from "gatsby"
import DocsLayout from "../components/Docs/DocsLayout"

import CategoryList from "../components/Docs/CategoryList"

import SEO from "../components/SEO"
// import ReactMarkdown from "react-markdown"

// import { Redirect } from '@reach/router';
class Help extends React.Component {
  render() {
    const help_doc = [
      {
        title: "How To Get Set Up On Google Ads",
        slug: "how-to-get-set-up-on-google-ads",
      },
      {
        title: "How to get set up on Facebook Ads",
        slug: "how-to-get-set-up-on-facebook-ads",
      },
      {
        title: "General",
        slug: "general",
      },
    ]
    return (
      <>
        <SEO
          title={"Brillea - Digital marketing help guides"}
          description={
            "Digital makreting help guides to help you get set up on facebook ads, and setup google ads"
          }
        />
        <DocsLayout>
          <article className="help-article">
            <header className="help-article__header">
              <h1 className="help-article__header-title">Help Articles</h1>
            </header>
          </article>
          <section className="help-article__content">
            <p>
              Select one of the following help categories to help you get set
              up:
            </p>
          </section>
          <section className="help-article__categoryList">
            <CategoryList catSlug={false} list={help_doc} />
          </section>
        </DocsLayout>
      </>
    )
  }
}

export default Help
